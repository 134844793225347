import React from 'react'
import { Box, Text, Image, Center } from '@chakra-ui/react'
import logo from '../../assets/logo.svg'
import success from '../../assets/success.gif'

const Success = () => {

    return (
        <Box
            p='20px'
            fontFamily='IBM Plex Sans, sans-serif'
        >
            <Center pb='100px'>
                <Image src={logo} />
            </Center>

            {/*  */}
            <Center pb='80px'>
                <Image src={success} />
            </Center>

            {/*  */}
            <Text fontWeight='bold' fontSize='40px' mb='30px' textAlign='center'>
                Payment Success
            </Text>

            {/*  */}
            <Text textAlign='center' fontSize='14px'>
                Thank you for making payment with Swahilies Pay
            </Text>
        </Box>
    )
}

export default Success