import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Box, Text, Flex, Image, Center, Input, Button, Select, useToast, Spinner } from '@chakra-ui/react'
import logo from '../assets/logo.svg';
import merchant from '../assets/merchant.svg';
// import countries from './data'

const Main = () => {

    const countries = [
        {"code":"AF","name": "Afghanistan"},
        {"code":"AX","name": "Aland Islands"},
        {"code":"AL","name": "Albania"},
        {"code":"DZ","name": "Algeria"},
        {"code":"AS","name": "American Samoa"},
        {"code":"AD","name": "Andorra"},
        {"code":"AO","name": "Angola"},
        {"code":"AI","name": "Anguilla"},
        {"code":"AQ","name": "Antarctica"},
        {"code":"AG","name": "Antigua And Barbuda"},
        {"code":"AR","name": "Argentina"},
        {"code":"AM","name": "Armenia"},
        {"code":"AW","name": "Aruba"},
        {"code":"AU","name": "Australia"},
        {"code":"AT","name": "Austria"},
        {"code":"AZ","name": "Azerbaijan"},
        {"code":"BS","name": "Bahamas"},
        {"code":"BH","name": "Bahrain"},
        {"code":"BD","name": "Bangladesh"},
        {"code":"BB","name": "Barbados"},
        {"code":"BY","name": "Belarus"},
        {"code":"BE","name": "Belgium"},
        {"code":"BZ","name": "Belize"},
        {"code":"BJ","name": "Benin"},
        {"code":"BM","name": "Bermuda"},
        {"code":"BT","name": "Bhutan"},
        {"code":"BO","name": "Bolivia"},
        {"code":"BA","name": "Bosnia And Herzegovina"},
        {"code":"BW","name": "Botswana"},
        {"code":"BV","name": "Bouvet Island"},
        {"code":"BR","name": "Brazil"},
        {"code":"IO","name": "British Indian Ocean Territory"},
        {"code":"BN","name": "Brunei Darussalam"},
        {"code":"BG","name": "Bulgaria"},
        {"code":"BF","name": "Burkina Faso"},
        {"code":"BI","name": "Burundi"},
        {"code":"KH","name": "Cambodia"},
        {"code":"CM","name": "Cameroon"},
        {"code":"CA","name": "Canada"},
        {"code":"CV","name": "Cape Verde"},
        {"code":"KY","name": "Cayman Islands"},
        {"code":"CF","name": "Central African Republic"},
        {"code":"TD","name": "Chad"},
        {"code":"CL","name": "Chile"},
        {"code":"CN","name": "China"},
        {"code":"CX","name": "Christmas Island"},
        {"code":"CC","name": "Cocos (Keeling) Islands"},
        {"code":"CO","name": "Colombia"},
        {"code":"KM","name": "Comoros"},
        {"code":"CG","name": "Congo"},
        {"code":"CD","name": "Congo Democratic Republic"},
        {"code":"CK","name": "Cook Islands"},
        {"code":"CR","name": "Costa Rica"},
        {"code":"CI","name": "Cote DI voire"},
        {"code":"HR","name": "Croatia"},
        {"code":"CU","name": "Cuba"},
        {"code":"CY","name": "Cyprus"},
        {"code":"CZ","name": "Czech Republic"},
        {"code":"DK","name": "Denmark"},
        {"code":"DJ","name": "Djibouti"},
        {"code":"DM","name": "Dominica"},
        {"code":"DO","name": "Dominican Republic"},
        {"code":"EC","name": "Ecuador"},
        {"code":"EG","name": "Egypt"},
        {"code":"SV","name": "El Salvador"},
        {"code":"GQ","name": "Equatorial Guinea"},
        {"code":"ER","name": "Eritrea"},
        {"code":"EE","name": "Estonia"},
        {"code":"ET","name": "Ethiopia"},
        {"code":"FK","name": "Falkland Islands (Malvinas)"},
        {"code":"FO","name": "Faroe Islands"},
        {"code":"FJ","name": "Fiji"},
        {"code":"FI","name": "Finland"},
        {"code":"FR","name": "France"},
        {"code":"GF","name": "French Guiana"},
        {"code":"PF","name": "French Polynesia"},
        {"code":"TF","name": "French Southern Territories"},
        {"code":"GA","name": "Gabon"},
        {"code":"GM","name": "Gambia"},
        {"code":"GE","name": "Georgia"},
        {"code":"DE","name": "Germany"},
        {"code":"GH","name": "Ghana"},
        {"code":"GI","name": "Gibraltar"},
        {"code":"GR","name": "Greece"},
        {"code":"GL","name": "Greenland"},
        {"code":"GD","name": "Grenada"},
        {"code":"GP","name": "Guadeloupe"},
        {"code":"GU","name": "Guam"},
        {"code":"GT","name": "Guatemala"},
        {"code":"GG","name": "Guernsey"},
        {"code":"GN","name": "Guinea"},
        {"code":"GW","name": "Guinea-Bissau"},
        {"code":"GY","name": "Guyana"},
        {"code":"HT","name": "Haiti"},
        {"code":"HM","name": "Heard Island & Mcdonald Islands"},
        {"code":"VA","name": "Holy See (Vatican City State)"},
        {"code":"HN","name": "Honduras"},
        {"code":"HK","name": "Hong Kong"},
        {"code":"HU","name": "Hungary"},
        {"code":"IS","name": "Iceland"},
        {"code":"IN","name": "India"},
        {"code":"ID","name": "Indonesia"},
        {"code":"IR","name": "Iran}, Islamic Republic Of"},
        {"code":"IQ","name": "Iraq"},
        {"code":"IE","name": "Ireland"},
        {"code":"IM","name": "Isle Of Man"},
        {"code":"IL","name": "Israel"},
        {"code":"IT","name": "Italy"},
        {"code":"JM","name": "Jamaica"},
        {"code":"JP","name": "Japan"},
        {"code":"JE","name": "Jersey"},
        {"code":"JO","name": "Jordan"},
        {"code":"KZ","name": "Kazakhstan"},
        {"code":"KE","name": "Kenya"},
        {"code":"KI","name": "Kiribati"},
        {"code":"KR","name": "Korea"},
        {"code":"KW","name": "Kuwait"},
        {"code":"KG","name": "Kyrgyzstan"},
        {"code":"LA","name": "Lao Peoples Democratic Republic"},
        {"code":"LV","name": "Latvia"},
        {"code":"LB","name": "Lebanon"},
        {"code":"LS","name": "Lesotho"},
        {"code":"LR","name": "Liberia"},
        {"code":"LY","name": "Libyan Arab Jamahiriya"},
        {"code":"LI","name": "Liechtenstein"},
        {"code":"LT","name": "Lithuania"},
        {"code":"LU","name": "Luxembourg"},
        {"code":"MO","name": "Macao"},
        {"code":"MK","name": "Macedonia"},
        {"code":"MG","name": "Madagascar"},
        {"code":"MW","name": "Malawi"},
        {"code":"MY","name": "Malaysia"},
        {"code":"MV","name": "Maldives"},
        {"code":"ML","name": "Mali"},
        {"code":"MT","name": "Malta"},
        {"code":"MH","name": "Marshall Islands"},
        {"code":"MQ","name": "Martinique"},
        {"code":"MR","name": "Mauritania"},
        {"code":"MU","name": "Mauritius"},
        {"code":"YT","name": "Mayotte"},
        {"code":"MX","name": "Mexico"},
        {"code":"FM","name": "Micronesia}, Federated States Of"},
        {"code":"MD","name": "Moldova"},
        {"code":"MC","name": "Monaco"},
        {"code":"MN","name": "Mongolia"},
        {"code":"ME","name": "Montenegro"},
        {"code":"MS","name": "Montserrat"},
        {"code":"MA","name": "Morocco"},
        {"code":"MZ","name": "Mozambique"},
        {"code":"MM","name": "Myanmar"},
        {"code":"NA","name": "Namibia"},
        {"code":"NR","name": "Nauru"},
        {"code":"NP","name": "Nepal"},
        {"code":"NL","name": "Netherlands"},
        {"code":"AN","name": "Netherlands Antilles"},
        {"code":"NC","name": "New Caledonia"},
        {"code":"NZ","name": "New Zealand"},
        {"code":"NI","name": "Nicaragua"},
        {"code":"NE","name": "Niger"},
        {"code":"NG","name": "Nigeria"},
        {"code":"NU","name": "Niue"},
        {"code":"NF","name": "Norfolk Island"},
        {"code":"MP","name": "Northern Mariana Islands"},
        {"code":"NO","name": "Norway"},
        {"code":"OM","name": "Oman"},
        {"code":"PK","name": "Pakistan"},
        {"code":"PW","name": "Palau"},
        {"code":"PS","name": "Palestinian Territory}, Occupied"},
        {"code":"PA","name": "Panama"},
        {"code":"PG","name": "Papua New Guinea"},
        {"code":"PY","name": "Paraguay"},
        {"code":"PE","name": "Peru"},
        {"code":"PH","name": "Philippines"},
        {"code":"PN","name": "Pitcairn"},
        {"code":"PL","name": "Poland"},
        {"code":"PT","name": "Portugal"},
        {"code":"PR","name": "Puerto Rico"},
        {"code":"QA","name": "Qatar"},
        {"code":"RE","name": "Reunion"},
        {"code":"RO","name": "Romania"},
        {"code":"RU","name": "Russian Federation"},
        {"code":"RW","name": "Rwanda"},
        {"code":"BL","name": "Saint Barthelemy"},
        {"code":"SH","name": "Saint Helena"},
        {"code":"KN","name": "Saint Kitts And Nevis"},
        {"code":"LC","name": "Saint Lucia"},
        {"code":"MF","name": "Saint Martin"},
        {"code":"PM","name": "Saint Pierre And Miquelon"},
        {"code":"VC","name": "Saint Vincent And Grenadines"},
        {"code":"WS","name": "Samoa"},
        {"code":"SM","name": "San Marino"},
        {"code":"ST","name": "Sao Tome And Principe"},
        {"code":"SA","name": "Saudi Arabia"},
        {"code":"SN","name": "Senegal"},
        {"code":"RS","name": "Serbia"},
        {"code":"SC","name": "Seychelles"},
        {"code":"SL","name": "Sierra Leone"},
        {"code":"SG","name": "Singapore"},
        {"code":"SK","name": "Slovakia"},
        {"code":"SI","name": "Slovenia"},
        {"code":"SB","name": "Solomon Islands"},
        {"code":"SO","name": "Somalia"},
        {"code":"ZA","name": "South Africa"},
        {"code":"GS","name": "South Georgia And Sandwich Isl."},
        {"code":"ES","name": "Spain"},
        {"code":"LK","name": "Sri Lanka"},
        {"code":"SD","name": "Sudan"},
        {"code":"SR","name": "Suri name"},
        {"code":"SJ","name": "Svalbard And Jan Mayen"},
        {"code":"SZ","name": "Swaziland"},
        {"code":"SE","name": "Sweden"},
        {"code":"CH","name": "Switzerland"},
        {"code":"SY","name": "Syrian Arab Republic"},
        {"code":"TW","name": "Taiwan"},
        {"code":"TJ","name": "Tajikistan"},
        {"code":"TZ","name": "Tanzania"},
        {"code":"TH","name": "Thailand"},
        {"code":"TL","name": "Timor-Leste"},
        {"code":"TG","name": "Togo"},
        {"code":"TK","name": "Tokelau"},
        {"code":"TO","name": "Tonga"},
        {"code":"TT","name": "Trinidad And Tobago"},
        {"code":"TN","name": "Tunisia"},
        {"code":"TR","name": "Turkey"},
        {"code":"TM","name": "Turkmenistan"},
        {"code":"TC","name": "Turks And Caicos Islands"},
        {"code":"TV","name": "Tuvalu"},
        {"code":"UG","name": "Uganda"},
        {"code":"UA","name": "Ukraine"},
        {"code":"AE","name": "United Arab Emirates"},
        {"code":"GB","name": "United Kingdom"},
        {"code":"US","name": "United States"},
        {"code":"UM","name": "United States Outlying Islands"},
        {"code":"UY","name": "Uruguay"},
        {"code":"UZ","name": "Uzbekistan"},
        {"code":"VU","name": "Vanuatu"},
        {"code":"VE","name": "Venezuela"},
        {"code":"VN","name": "Vietnam"},
        {"code":"VG","name": "Virgin Islands}, British"},
        {"code":"VI","name": "Virgin Islands}, U.S."},
        {"code":"WF","name": "Wallis And Futuna"},
        {"code":"EH","name": "Western Sahara"},
        {"code":"YE","name": "Yemen"},
        {"code":"ZM","name": "Zambia"},
        {"code":"ZW","name": "Zimbabwe"}
    ];

    const toast = useToast()

    const [selectedCountry, setSelectedCountry] = useState("Tanzania");
    const [supplierName, setSupplierName] = useState("")
    const [supplierNumber, setSupplierNumber] = useState("")
    const [amount, setAmount] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [customerPhone, setCustomerPhone] = useState("")
    const [timeReg, setTimeReg] = useState("")
    const [region, setRegion] = useState("")
    const [addressu, setAddress] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(true)
    const [countryCode, setCountryCode] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true
    });


    const getOrder = () => {
        setDataLoading(true);
        axios
        .post('https://swahiliesapi.invict.site/Api', {
            "code": 100,
            "api": 180,
            "data": {
                "order_id": window.location.pathname.substring(1)
            }
        })
        .then((res) => {
            let data = res.data;
            setSelectedCountry(data.order.country);
            setSupplierName(data.order.supplier.supplier_name)
            setSupplierNumber(data.order.supplier.supplier_number)
            setCustomerName(data.order.customer_name)
            setCustomerPhone(data.order.phone_number)
            setAmount(data.order.amount.toString())
            setTimeReg(data.order.registered)

            setDataLoading(false);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    // changed value
    const changedCountry = (value) => {
        setSelectedCountry(value);
        const result = countries.filter(country => country.name === value);
        setCountryCode(result[0].code);
    }

    const onRegionChange = (value) => {
        setRegion(value)
    }

    const onEmailChange = (value) => {
        setEmailAddress(value)
    }

    const onAddressChange = (value) => {
        setAddress(value)
    }

    // get url
    const getUrl = () => {
        setIsLoading(true);
        axios
        .post('https://swahiliesapi.invict.site/Api', {
            "code": 101,
            "api": 180,
            "data": {
                "order_id": window.location.pathname.substring(1),
                "country_code": countryCode,
                "is_local": selectedCountry === "Tanzania" ? true : false,
                "city": region,
                "address": addressu,
                "email": emailAddress,
            }
        })
        .then((res) => {
            let data = res.data;
            // console.log(data);
            // window.location.replace(data.url)
            if (data.code === 200) {
            window.location.href = data.url
            } else {
                toast({
                    title: 'Something went wrong.',
                    description: "Please try again or contact us via +255 682 411 725",
                    status: 'error',
                    duration: 15000,
                    isClosable: true,
                })
            }
            setIsLoading(false)
        })
        .catch((e) => {
            setIsLoading(false)
            console.log(e);
        });
    }

    useEffect(() => {
        getOrder();
        // console.log(countries)
    }, []);
    return (
        
        <Box
            p='20px'
            fontFamily='IBM Plex Sans, sans-serif'
        >
            {/* Header */}

            {/* logo */}
            {
            dataLoading === false ? 
            <>
            <Flex
                justifyContent='space-between'
                alignItems='center'
                pb='30px'
            >
                <Image src={logo} w='100px'  pb='10px' />

                {/* invoice */}
                <Text
                    fontSize='12px'
                    fontWeight='400'
                    color='grey'
                >
                    Supper ID: {supplierNumber}
                </Text>
            </Flex>

            {/* image */}
            <Center>
                <Image src={merchant} w='129' h='129' pb='10px' />
            </Center>
            {/* name */}
            <Text
                fontSize='20px'
                fontWeight='600'
                textAlign='center'
                pb='30px'
            >
                {supplierName}
            </Text>

            {/* location */}
            <Box
                h='40px'
                backgroundColor='rgba(196, 196, 196, 0.2)'
                w='100%'
                borderRadius='5px'
                pr='8px'
                pl='8px'
                pt='10px'
                pb='3px'
                alignItems='center'
                mb='40px'
            >
                <Flex justifyContent='space-between'>
                    <Text
                        fontSize='15px'
                    >
                        Current Location
                    </Text>

                    {/* selector */}
                    <Flex alignItems='center' w='40%'>
                        <Select variant='unstyled' onChange={(e) => changedCountry(e.target.value)} placeholder={selectedCountry}>
                            {countries.map((row, index) => 
                                <option key={index} value={row.name}>{row.name}</option>
                            )}
                        </Select>
                    </Flex>
                </Flex>
            </Box>

            {/* inputs */}
            {selectedCountry !== "Tanzania" ?
            <>
                <Box pb='20px'>
                    <Text
                        fontSize='14px'
                        pb='10px'
                    >
                        Region or State
                    </Text>
                    <Input placeholder='Region or State' onChange={(e) => onRegionChange(e.target.value)} />
                </Box>

                <Box pb='20px'>
                    <Text
                        fontSize='14px'
                        pb='10px'
                    >
                        Email
                    </Text>
                    <Input placeholder='Email address' type='email' onChange={(e) => onEmailChange(e.target.value)} />
                </Box>

                <Box pb='20px'>
                    <Text
                        fontSize='14px'
                        pb='10px'
                    >
                        Address
                    </Text>
                    <Input placeholder='Address' onChange={(e) => onAddressChange(e.target.value)}/>
                </Box>
            </>
            : 
            null
            }
            

            <Flex justifyContent='space-between' pb='45px'>
                <Box w='47%'>
                    <Text
                        fontSize='14px'
                        pb='10px'
                    >
                        Full Name
                    </Text>
                    <Input placeholder='Your Name' disabled={customerName !== "" ? true : false} value={customerName} />
                </Box>
                {/*  */}
                <Box w='47%'>
                    <Text
                        fontSize='14px'
                        pb='10px'
                    >
                        Phone Number
                    </Text>
                    <Input placeholder='Enter your Phone Number' disabled={customerPhone !== "" ? true : false} value={customerPhone} />
                </Box>
            </Flex>

            {/* summary */}
            <Box
                backgroundColor='#F0F0F0'
                // h='100px'
                w='100%'
                borderRadius='4px'
                p='15px'
                mb='50px'
            >
                {/* product details */}
                <Flex justifyContent='space-between' pb='40px'>
                    <Text
                        fontSize='14px'
                        color='gray'
                    >
                        Paying amount
                    </Text>
                    <Text
                        fontSize='14px'
                    >
                        TZS {formatter.format(amount)}
                    </Text>
                </Flex>

                {/* line */}
                <Center pb='15px'>
                    <Box backgroundColor='rgba(246, 164, 20, 0.4)' w='90%' h='1px'></Box>
                </Center>
                
                {/* total */}
                <Flex justifyContent='space-between' >
                    <Text
                        fontSize='14px'
                        fontWeight='700'
                    >
                        Total
                    </Text>
                    <Text
                        fontSize='14px'
                        fontWeight='700'
                        color='#F6A414'
                    >
                        TZS {formatter.format(amount)}
                    </Text>
                </Flex>
            </Box>

            {/* button */}
            {isLoading ?
                <Button
                    color='white'
                    backgroundColor='#00B607'
                    w='100%'
                    mb='10px'
                    isLoading
                    loadingText='Sending...'
                />
                :
                <Button
                    color='white'
                    backgroundColor='#00B607'
                    w='100%'
                    mb='10px'
                    onClick={getUrl}
                >
                    Pay TZS {formatter.format(amount)}
                </Button>
            }
            
            

            {/* order date */}
            <Text
                fontSize='12px'
                fontWeight='400'
                color='grey'
                textAlign='center'
            >
                Order Date: {timeReg}
            </Text>
            </>
            : 
            <Center mt='200px'>
                <Spinner size='xl' color='#F6A414' />
            </Center>
            }
        </Box>
    )
}

export default Main