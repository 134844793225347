import React from 'react'
import { Box, Text, UnorderedList, Image, Center, ListItem, } from '@chakra-ui/react'
import logo from '../../assets/logo.svg'
import declined from '../../assets/declined.svg'

const Cancel = () => {
    return (
        <Box
            p='20px'
            fontFamily='IBM Plex Sans, sans-serif'
        >
            <Center pb='80px'>
                <Image src={logo} />
            </Center>

            {/*  */}
            <Center pb='50px'>
                <Image src={declined} />
            </Center>

            {/*  */}
            <Text fontWeight='bold' fontSize='35px' mb='30px' textAlign='center'>
                Payment Failed
            </Text>
            <Text fontSize='15px' mb='30px'>
                We're sorry, but there was a problem processing your payment. Please try to:
            </Text>

            <UnorderedList mb='50px'>
                <ListItem>Check your account balance</ListItem>
                <ListItem>Use other card for payment</ListItem>
                <ListItem>Contact your card provider</ListItem>
            </UnorderedList>

            {/*  */}
            <Text textAlign='center'>
                If you could not solve the issue please contact us here: +255 682 411 725
            </Text>
        </Box>
    )
}

export default Cancel