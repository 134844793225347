import React from 'react';
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import Main from './components';
// import Cancel from './components/cancel';
// import Success from './components/success';
import ChoosePage from './components/choose';

const AppWrapper = () => {
  let routes = useRoutes([
    { path: "/:id", element: <Main /> },
    // { path: "/declined", element: <Cancel /> },
    { path: "/status/:id", element: <ChoosePage /> },
  ]);
  return routes;
};

function App() {
  return (
    <Router>
      <ChakraProvider>
        <AppWrapper />
      </ChakraProvider>
    </Router>
  );
}

export default App;
