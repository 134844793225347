import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { Spinner, Center } from '@chakra-ui/react'
import Cancel from '../cancel'
import Success from '../success'

const ChoosePage = () => {

    const [isSuccess, setIsSuccess] = useState(false)
    const [dataLoading, setDataLoading] = useState(true)

    const getOrderData = () => {
        setDataLoading(true);
        axios
        .post('https://swahiliesapi.invict.site/Api', {
            "code": 102,
            "api": 180,
            "data": {
                "transaction_id": window.location.pathname.substring(8)
            }
        })
        .then((res) => {
            let data = res.data;
            // console.log(data)
            if(data.transaction.status === "paid") {
                setIsSuccess(true);
                setDataLoading(false);
            } else {
                setIsSuccess(false)
                setDataLoading(false)
            }

            
        })
        .catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        getOrderData();
        // console.log(window.location.pathname.substring(8))
        // console.log(countries)
    }, []);

    return (
        <>
        {dataLoading === false ?
            isSuccess === true
                ?
                <Success />
                :
                <Cancel />
            :
            
            <Center mt='200px'>
                <Spinner size='xl' color='#F6A414' />
            </Center>
        }
        </>
    )
}

export default ChoosePage